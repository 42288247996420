@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap);
body {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-size: 28px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

h2 {
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

h3 {
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

table {
    /* border-collapse: inherit; */
    border: none;
}

th {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    /* color: #44464880; */
    border: none;
}

.table thead th {
    border-bottom: none;
}
.table td,
.table th {
    border-top: none;
    padding: 0.9rem;
}
/* tr:nth-child(odd) {
    background-color: white;
    border-style: hidden;
    border-bottom: 1px solid gray;
    padding: 14px 0;
}

tr:nth-child(even) {
    background-color: #f7f8fc;
    border-style: hidden;
    border-bottom: 1px solid gray;
    padding: 14px 0;
} */

tr {
    padding: 14px 0;
}

td {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #444648;
    border: none;
}

input {
    color: #4e4e4ecc !important;
    border-radius: 5px !important;
    background-color: #f7f8fc !important;
    border-style: hidden !important;
    padding: 5px 10px;
}

input:focus {
    color: #4e4e4eff !important;
    outline: none;
    border-radius: 5px !important;
    background-color: #f7f8fc !important;
    border-style: hidden !important;
    padding: 5px 10px;
}

select {
    border-color: #4e4e4ecc;
    color: #4e4e4ecc;
    border-radius: 5px;
    padding: 0px 10px;
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
}

select:focus {
    border-color: #4e4e4eff;
    color: #4e4e4eff;
    border-radius: 5px;
    padding: 0px 10px;
    outline: none;
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
}

.text-danger {
    color: #ff595ecc;
    text-decoration: underline;
}

.text-danger:hover {
    color: #ff595eff;
}

.btn-link {
    color: #8a4ee9cc;
}

.btn-link:hover,
.btn-link:active {
    color: #6a2ec9ff !important;
    border-color: #6a2ec9ff !important;
    text-decoration: underline;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus {
    background-color: #8a4ee9cc;
    border-color: #8a4ee9cc;
    color: white;
    box-shadow: unset;
}

.btn-primary:hover {
    background-color: #6a2ec9ff !important;
    border-color: #6a2ec9ff !important;
    color: white;
    box-shadow: unset;
}

.btn-primary:disabled,
.btn-primary:disabled:hover {
    background-color: #252743ff !important;
    border-color: #252743ff !important;
    color: white;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #6a2ec9ff !important;
    border-color: #6a2ec9ff !important;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-secondary,
button-secondary {
    color: #8a4ee9cc;
    border-color: #8a4ee9cc;
    background-color: white;
}

.btn-secondary:hover,
button-secondary:hover {
    color: #8a4ee9ff;
    border-color: #8a4ee9ff;
    color: white;
}

.btn-warning,
.btn-danger,
button-warning {
    background-color: #ff595ecc;
    border-color: #ff595ecc;
    color: white;
}

.btn-warning:hover,
.btn-danger:hover,
button-warning:hover {
    background-color: #ff595eff;
    border-color: #ff595eff;
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* .Toastify__toast--error {
    background: #ff595e !important;
}

.Toastify__toast--success {
    background: #2dc8a8 !important;
} */

.shimmer {
   background: #f6f7f8;
   /* background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%); */
   background-repeat: no-repeat;
   background-size: 800px 104px; 
   display: inline-block;
   position: relative; 
   
   -webkit-animation-duration: 1s;
   -webkit-animation-fill-mode: forwards; 
   -webkit-animation-iteration-count: infinite;
   -webkit-animation-name: placeholderShimmer;
   -webkit-animation-timing-function: linear;
   }
 
 /* box {
   height: 104px;
   width: 100px;
 }
 
 div {
   display: inline-flex;
   flex-direction: column; 
   margin-left: 25px;
   margin-top: 15px;
   vertical-align: top; 
 } */
 
 /* lines {
   height: 10px;
   margin-top: 10px;
   width: 200px; 
 }
 
 photo {
   display: block!important;
   width: 325px; 
   height: 100px; 
   margin-top: 15px;
 }
  */

 @-webkit-keyframes placeholderShimmer {
   0% {
     background-position: -468px 0;
   }
   
   100% {
     background-position: 468px 0; 
   }
 }
 
 
