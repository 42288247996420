.shimmer {
   background: #f6f7f8;
   /* background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%); */
   background-repeat: no-repeat;
   background-size: 800px 104px; 
   display: inline-block;
   position: relative; 
   
   -webkit-animation-duration: 1s;
   -webkit-animation-fill-mode: forwards; 
   -webkit-animation-iteration-count: infinite;
   -webkit-animation-name: placeholderShimmer;
   -webkit-animation-timing-function: linear;
   }
 
 /* box {
   height: 104px;
   width: 100px;
 }
 
 div {
   display: inline-flex;
   flex-direction: column; 
   margin-left: 25px;
   margin-top: 15px;
   vertical-align: top; 
 } */
 
 /* lines {
   height: 10px;
   margin-top: 10px;
   width: 200px; 
 }
 
 photo {
   display: block!important;
   width: 325px; 
   height: 100px; 
   margin-top: 15px;
 }
  */

 @-webkit-keyframes placeholderShimmer {
   0% {
     background-position: -468px 0;
   }
   
   100% {
     background-position: 468px 0; 
   }
 }
 
 